import React from "react";
import {useState, useRef,useEffect } from "react";
// import Autocomplete from "./Autocomplete";
import "./styles.css";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
 const axios = require('axios');

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

const App = () => {
  const [suggestions, setSuggestions] = useState(null);
  const [input, setInput] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [lat,setLat] = useState(-33.87271571);
  const [lon,setLon] = useState(151.20755488);
  const map = useRef(null);
  const [zoom, setZoom] = useState(16);
  const [showMarker,setShoMarker] = useState(false);
  const mapContainer = useRef(null);

    useEffect(() => {
      console.log("useEffect");
      
    // if (map.current) return; // initialize map only once
    
    /* eslint-disable no-unused-vars */
    /* eslint-enable react-hooks/exhaustive-deps */
    if(showMarker) {
      map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/dark-v10',
      center: [lon, lat],
      zoom: zoom
      
      
    });
    const marker = new mapboxgl.Marker()
      .setLngLat([lon, lat])
      .setPopup(new mapboxgl.Popup().setHTML("<h1>"+ input +"</h1>"))
      .addTo(map.current); // add the marker to the map
    }
    /* eslint-enable no-unused-vars */
    /* eslint-enable react-hooks/exhaustive-deps */
  }, [lat,lon,zoom,input,showMarker]);

useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/dark-v10',
      center: [151.20755488, -33.87271571],
      zoom: 8
      
      
    });

},[]);
//   useEffect(() => {
// if (!map.current) return; // wait for map to initialize
// // map.current.on('move', () => {
// // setLon(map.current.getCenter().lon.toFixed(4));
// // setLat(map.current.getCenter().lat.toFixed(4));
// // setZoom(map.current.getZoom().toFixed(2));
// // });
// });

const onChange = (e) => {
    
    const userInput = e.target.value;
   
    // const addressData = []
    // setShowSuggestions(true)
    setInput(e.target.value)
    if(e.target.value.length > 5) { 
      setTimeout(() => {
      console.log("debouncing")
    }, 300);
    axios.get(`https://addressapi.jason120au.net?q=${userInput}&override=true`)
  .then(function (response) {
    // handle success

    // console.log(response.data);
    
        // console.log(item.text)
        
        setSuggestions(response.data);

    


  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .then(function () {
    // always executed
  });
  // console.log(addressData)
    // 
}
}
// console.log(suggestions)
console.log(input);

const onFocus = () => {
  console.log("onFocus")
  setShowSuggestions(true);
  // setInput(text)

}

const onClick = (text,lat,lon) => {

  setShowSuggestions(false);
  setInput(text)
  setLat(lat)
  setLon(lon)
  setZoom(16)
  setShoMarker(true)
  
}
  return (
    <div>
      <input type="text" placeholder="Please enter an address" onChange={onChange} onFocus={() =>onFocus()} id="textInput" value={input} autoComplete="off"></input>
      <ul className="suggestions" key="test"> 
      {suggestions && 
      suggestions.map((item, index) => { 
        return (
        <div key={index}>
        {showSuggestions &&
        <li className="suggestions" onClick={()=>onClick(item.text,item.lat,item.lon)} key={index}>{item.text}</li>}
        </div>
        )
      }
      
      )}
      </ul>
      <div>
{/* <div className="sidebar">
Longitude: {lon} | Latitude: {lat} | Zoom: {zoom}
</div> */}
<div ref={mapContainer} className="map-container" />
</div>
    
    </div>
  );
};

export default App;